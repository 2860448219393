<template>
    <div class="page product-manage-page">
        <div class="opt-bar">
            <el-button type="primary" @click="addCallBack">发布商品</el-button>
        </div>
        <Search :items="searchItems" :data.sync="searchData" @search="search" />
        <!-- <el-radio-group
            v-model="sta"
            @change="search"
            class="table-tab-container"
        >
            <el-radio-button
                v-for="tab in tableTabs"
                :key="tab.value"
                :label="tab.value"
                >{{ tab.label }}</el-radio-button
            >
        </el-radio-group> -->
        <div class="table-tab-panel">
            <el-radio-group v-model="sta" size="small" @change="search">
                <el-radio-button
                    v-for="tab in tableTabs"
                    :key="tab.value"
                    :label="tab.value"
                >{{ tab.label }}</el-radio-button>
            </el-radio-group>
        </div>
        <Table
            :tableColumns="tableColumns"
            :tableData="tableData"
            :tableOperate="tableOperate"
            :tableMap="tabMap"
            :border="false"
            :currentPage.sync="pageIndex"
            :pageSize.sync="pageSize"
            :total="total"
            :moreOpt="true"
            v-loading="loading"
        />
        <Publish :visible.sync="pvis" v-if="pvis" :page.sync="pageData" />
        <spec-edit-panel
            :specEditFlag.sync="specEditFlag"
            :page.sync="pageData"
            @refresh="getTableData"
            v-if="specEditFlag"
        />
        <input
            ref="copyRef"
            style="position: fixed; top: -9999px; left: -9999px"
        />
    </div>
</template>

<script>
import data from "./index";
import Publish from "./publish";
import SpecEditPanel from "./specEditPanel";
import { RESET_SEARCH } from "../../events/eventBus";
export default {
    mixins: [data],
    data() {
        return {
            pvis: false,
            searchData: {},
            sta: -1,
            scb: null,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            pageData: {},
            currRow: null,
            specEditFlag: false,
            loading: false
        };
    },
    mounted() {
        this.getTableData();
        this.$EventBus.$on(RESET_SEARCH, () => {
            this.searchData = {};
            this.sta = -1;
            this.search();
        });
    },
    computed: {
        pgd() {
            return { ps: this.pageSize, cp: this.pageIndex };
        }
    },
    watch: {
        "searchData.product_category"() {
            if (
                this.searchData.product_category &&
                this.searchData.product_category.length == 2
            ) {
                this.searchData.fCateId = this.searchData.product_category[0];
                this.searchData.sCateId = this.searchData.product_category[1];
            } else {
                delete this.searchData.sCateId;
                delete this.searchData.fCateId;
            }
        },
        pgd() {
            this.getTableData();
        },
        pvis() {
            this.getTableData();
        }
    },
    methods: {
        getTableData() {
            this.loading = true;
            if (this.sta > 0) {
                this.searchData.sta = this.sta;
            } else {
                delete this.searchData.sta;
            }
            this.$post("/shop/api/goods/getOnPluList", {
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                ...this.searchData
            }).then(data => {
                this.tableData = data.datas;
                this.total = data.recordCount;
                this.loading = false;
            });
        },
        search() {
            this.pageIndex = 1;
            this.getTableData();
        },
        addCallBack() {
            // this.pageData = {
            //     iconImg:
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
            //     pluImgList: [
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg",
            //         "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg"
            //     ]
            // };
            this.pvis = true;
        },
        copyLink(row) {
            this.$refs.copyRef.value = `views/onlinemall/pages/productDetail/index?type=1&onPluId=${row.onPluId}`;
            this.$refs.copyRef.select();
            document.execCommand("copy");
            this.$message.success("链接复制成功");
        },
        edit(row) {
            this.pageData = row;
            this.pvis = true;
        },
        editSpec(row) {
            this.pageData = row;
            this.specEditFlag = true;
            // this.edit(row);
        },
        updateStat(row) {
            let json = {
                1: {
                    text: "删除",
                    sta: 0
                },
                5: {
                    text: "下架",
                    sta: 9
                },
                9: {
                    text: "上架",
                    sta: 5
                }
            };
            let sta, text;
            if (row.sta)
                this.$confirm(`确定执行${json[row.sta].text}操作吗？`, {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$post("/shop/api/goods/updateStat", {
                            onPluId: row.onPluId,
                            sta: json[row.sta].sta
                        }).then(data => {
                            this.$message({
                                message: `${json[row.sta].text}成功`,
                                type: "success"
                            });
                            this.getTableData();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            message: `取消${json[row.sta].text}`,
                            type: "warning"
                        });
                    });
        },
        remoteProductCategory(query) {
            return this.$post("/shop/api/goods/getAllOnCate", {});
        }
    },
    components: { Publish, SpecEditPanel }
};
</script>

<style lang="scss">
.page.product-manage-page {
    position: relative;
    display: flex;
    flex-direction: column;
    .opt-bar {
        padding: 20px 20px 0;
    }
    .table-tab-container {
        background-color: #fff;
        padding-left: 20px;
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            box-shadow: none;
        }
        .el-radio-button {
            .el-radio-button__inner {
                background: #eee;
                border-bottom: none;
            }

            &.is-active {
                .el-radio-button__inner {
                    background: #fff;
                    border: 1px solid #ccc;
                    border-bottom: none;
                    color: #000;
                }
            }
        }
    }
}
</style>