<template>
    <div
        :class="[
            'inner-page publish-product-page',
            { 'high-box': specEditFlag }
        ]"
        v-if="visible && datafinish"
        v-loading="pageLoad"
    >
        <i class="iconfont icon-chuyidong1 icon-close-page" @click="cancel"></i>
        <div class="steps">
            <div :class="['step-1 step', { active: step > 0 }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="circle">1</p>
                    <p class="text">编辑基本信息</p>
                </div>
            </div>
            <div :class="['step-1 step', { active: step > 1 }]">
                <p class="line"></p>
                <div class="step-intro">
                    <p class="circle">2</p>
                    <p class="text">编辑商品详情</p>
                </div>
            </div>
        </div>
        <div v-if="step == 1">
            <el-form
                ref="form1"
                :model="form"
                :rules="rule1"
                label-position="right"
                v-if="visible"
            >
                <div class="info-item" v-for="item in data" :key="item.title">
                    <div class="item-title">{{ item.title }}</div>
                    <div
                        class="common-item spec-common-item"
                        v-if="item.type == 'spec_price'"
                    >
                        <el-button type="primary" @click="addSpec"
                            >添加商品规格</el-button
                        >
                        <div
                            class="spec-list"
                            v-if="
                                specList.length > 0 || specListData.length > 0
                            "
                        >
                            <div
                                class="spec-item"
                                v-for="(item, index) in specList"
                                :key="item.id"
                            >
                                <div class="spec-name spec-extra">
                                    <p class="label">规格名:</p>
                                    <el-input
                                        v-model="item.specName"
                                        maxlength="20"
                                        @blur="onSpecNameBlur(item)"
                                        @input="onSpecChange"
                                    ></el-input>
                                    <p
                                        class="add-spec-btn"
                                        @click="addSpecValue(index)"
                                    >
                                        添加规格值
                                    </p>
                                </div>
                                <div class="spec-value spec-extra">
                                    <p class="label">规格值:</p>
                                    <div class="specs-container">
                                        <div
                                            class="eip-con"
                                            v-for="(it, idx) in item.specs"
                                            :key="idx"
                                        >
                                            <el-input
                                                v-model="it.name"
                                                maxlength="20"
                                                @blur="
                                                    onSpecValueBlur(
                                                        item,
                                                        it,
                                                        idx
                                                    )
                                                "
                                                @input="onSpecChange"
                                            ></el-input>
                                            <!-- <div
                                                class="spec-img-uploader"
                                                v-if="index < 1"
                                            >
                                                <common-upload
                                                    :item="specImgConfig"
                                                    :data="it"
                                                />
                                            </div> -->
                                            <div
                                                v-if="idx > 0"
                                                class="close-spec"
                                                @click="
                                                    closeSpecValue(index, idx)
                                                "
                                            >
                                                <i
                                                    class="iconfont icon-chuyidong1"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="close-spec"
                                    @click="closeSpec(index)"
                                >
                                    <i class="iconfont icon-chuyidong1"></i>
                                </div>
                            </div>
                            <!-- <el-button
                                type="primary"
                                style="margin: 10px 0"
                                @click="evaluateSpecList"
                                >生成规格列表</el-button
                            > -->
                        </div>
                        <div class="spec-table" v-if="specListData.length > 0">
                            <div
                                :class="[
                                    'table-container-panel',
                                    { loading: load }
                                ]"
                            >
                                <el-table
                                    :data.sync="specListData"
                                    v-if="!load"
                                    height="300"
                                >
                                    <template v-for="(item, index) in specList">
                                        <el-table-column
                                            v-if="
                                                item.specName &&
                                                item.specs.length > 0
                                            "
                                            :key="item.specName + '' + index"
                                            :label="item.specName"
                                            :prop="item.specName"
                                        ></el-table-column>
                                    </template>
                                    <el-table-column label="库存(件)">
                                        <template slot="header">
                                            <p>
                                                <b style="color: red">* </b>
                                                库存(件)
                                            </p>
                                        </template>
                                        <template slot-scope="scope">
                                            <el-input
                                                :value="scope.row.stkNum"
                                                maxlength="8"
                                                @input="
                                                    onListInput(
                                                        $event,
                                                        'stkNum',
                                                        scope.$index
                                                    )
                                                "
                                                @blur="
                                                    onListBlur(
                                                        $event,
                                                        'stkNum',
                                                        scope.$index
                                                    )
                                                "
                                                type="number"
                                                :min="0"
                                                :max="99999"
                                                :class="[
                                                    'test-error',
                                                    {
                                                        error:
                                                            (!scope.row
                                                                .stkNum ||
                                                                scope.row
                                                                    .stkNum ==
                                                                    '') &&
                                                            check
                                                    }
                                                ]"
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="商品价格(元)">
                                        <template slot="header">
                                            <p>
                                                <b style="color: red">* </b>
                                                商品价格(元)
                                            </p>
                                        </template>
                                        <template slot-scope="scope">
                                            <el-input
                                                :value="scope.row.price"
                                                @input="
                                                    onListInput(
                                                        $event,
                                                        'price',
                                                        scope.$index
                                                    )
                                                "
                                                @blur="
                                                    onListBlur(
                                                        $event,
                                                        'price',
                                                        scope.$index
                                                    )
                                                "
                                                type="number"
                                                maxlength="8"
                                                min="0"
                                                max="99999"
                                                :class="[
                                                    'test-error',
                                                    {
                                                        error: (!scope.row.price || scope.row.price == 0) && check
                                                    }
                                                ]"
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="重量(kg)">
                                        <template slot-scope="scope">
                                            <el-input
                                                :value="scope.row.skuWeight"
                                                maxlength="8"
                                                type="number"
                                                :min="0"
                                                :max="99999"
                                                @input="
                                                    onListInput(
                                                        $event,
                                                        'skuWeight',
                                                        scope.$index
                                                    )
                                                "
                                                @blur="
                                                    onListBlur(
                                                        $event,
                                                        'skuWeight',
                                                        scope.$index
                                                    )
                                                "
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="商品条码">
                                        <template slot-scope="scope">
                                            <el-input
                                                maxlength="10"
                                                v-model="scope.row.skuCode"
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="multi-setting-panel">
                                <div class="label-ml">批量设置</div>
                                <el-select
                                    class="setting-select"
                                    v-model="multiSettingSelect"
                                >
                                    <el-option
                                        v-for="opt in multiSettingOption"
                                        :key="opt.value"
                                        :value="opt.value"
                                        :label="opt.label"
                                    ></el-option>
                                </el-select>
                                <el-input
                                    class="setting-value"
                                    type="number"
                                    :min="0"
                                    :max="99999"
                                    maxlength="8"
                                    @input="multiValueInput"
                                    v-model="multiSettingValue"
                                />
                                <el-button type="primary" @click="multiSetValue"
                                    >确定</el-button
                                >
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div
                            class="common-item"
                            v-for="it in item.items"
                            :key="it.key"
                        >
                            <div
                                class="wack_dialog-con"
                                v-if="it.type == 'wack_dialog'"
                                @click="wackPruDialog"
                            >
                                <div class="label-1">选择商品</div>
                                <el-input
                                    disabled
                                    v-model="pluInfo.pluName"
                                    :class="{ 'wack-input-error': wackError }"
                                />
                                <span v-if="wackError" class="error-wack"
                                    >请选择商品</span
                                >
                            </div>
                            <template v-else>
                                <el-form-item :label="it.label" :prop="it.key">
                                    <component
                                        :is="'common-' + (it.type || 'input')"
                                        :item="it"
                                        :data.sync="form"
                                    ></component>
                                </el-form-item>
                                <p class="item-tips">{{ it.tip }}</p>
                            </template>
                        </div>
                    </template>
                </div>
            </el-form>
            <div class="btn-content">
                <p class="search-btn search" @click="nextStep">下一步</p>
                <p class="search-btn reset" @click="cancel">取消</p>
            </div>
        </div>
        <div v-if="step == 2">
            <div class="info-item">
                <div class="item-title">
                    <b style="color: #f56c6c">*</b>详情描述
                    <span
                        v-if="showError"
                        style="color: #f56c6c; font-size: 12px"
                        >请输入详情表述</span
                    >
                </div>
                <div class="common-item">
                    <div class="detail">
                        <component
                            :is="'common-upload'"
                            :item="{
                                key: 'detailInfo',
                                tip: '（尺寸： 300px*300px）',
                                limit: 10,
                                files: true
                            }"
                            :data.sync="form"
                        ></component>
                    </div>
                </div>
            </div>

            <div class="btn-content">
                <p class="search-btn reset" @click="prevStep">上一步</p>
                <p class="search-btn search" @click="submit">保存</p>
            </div>
        </div>
        <pru-select :visible.sync="pruVisible" @selectPlu="selectPlu" />
    </div>
</template>

<script>
import component from "../../../../components/common/component";
import publishData from "./publishData";
import pruSelect from "./pruSelect";
export default {
    mixins: [publishData],
    data() {
        return {
            datafinish: false,
            pageLoad: false,
            check: false,
            load: false,
            step: 1,
            form: {},
            showError: false,
            specList: [],
            specListData: [],
            selfChange: false,
            pruVisible: false,
            pluInfo: {},
            wackError: false,
            specImgConfig: {
                key: "img",
                limit: 1,
                files: true
            },
            multiSettingSelect: "stkNum",
            multiSettingValue: "",
            multiSettingOption: [
                { label: "库存", value: "stkNum" },
                { label: "商品价格", value: "price" },
                { label: "重量", value: "skuWeight" }
            ]
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        page: {
            type: Object,
            default: () => {
                return {};
            }
        },
        specEditFlag: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.page.onPluId) {
            this.$post("/shop/api/goods/getDetail", {
                onPluId: this.page.onPluId
            }).then(data => {
                this.datafinish = true;
                let arr = [];
                this.pluInfo = data;
                data.product_category = [data.fCateId, data.sCateId];
                data.expDelivery && arr.push("2");
                data.selfDelivery && arr.push("1");
                data.distribution_mode = arr;
                this.form = data;
                if (
                    data.pluSku &&
                    Object.prototype.toString.call(JSON.parse(data.pluSku)) ==
                        "[object Object]"
                ) {
                    let pluSku = JSON.parse(data.pluSku);
                    Object.keys(pluSku).forEach(key => {
                        let spItem = {};
                        spItem.specName = key;
                        spItem.specs = [];
                        pluSku[key].forEach(item => {
                            spItem.specs.push({ name: item });
                        });
                        this.specList.push(spItem);
                    });
                }
                if (data.skuDtoList) {
                    let skuList = data.skuDtoList;
                    skuList.forEach(item => {
                        let skuDetail = JSON.parse(item.skuDetail),
                            skuKeys = Object.keys(skuDetail),
                            temp = {};
                        temp.stkNum = item.stkNum;
                        temp.price = item.price;
                        temp.skuWeight = item.skuWeight;
                        temp.skuCode = item.skuCode;
                        for (let i = 0, len = skuKeys.length; i < len; i++) {
                            temp[skuKeys[i]] = skuDetail[skuKeys[i]];
                        }
                        this.specListData.push(temp);
                    });
                }
            });
        } else {
            this.datafinish = true;
        }

        this.selfChange = true;
    },
    watch: {
        page: {
            deep: true,
            handler() {
                this.form = this.page;
            }
        },
        "form.sno"() {
            if (this.form.sno.length > 5) {
                this.form.sno = parseInt(this.form.sno / 10)
            }
        },
        "form.iconImg"() {
            if (this.form.iconImg && this.$refs.form1) {
                this.$refs.form1.validateField("iconImg");
            }
        },
        "form.pluImgList"() {
            if (
                this.form.pluImgList &&
                this.form.pluImgList.length > 0 &&
                this.$refs.form1
            ) {
                this.$refs.form1.validateField("pluImgList");
            }
        },
        "form.product_category"() {
            if (
                this.form.product_category &&
                this.form.product_category.length > 0
            ) {
                this.form.fCateId = this.form.product_category[0];
                this.form.sCateId = this.form.product_category[1];
            } else {
                delete this.form.sCateId;
                delete this.form.fCateId;
            }
        },
        "form.distribution_mode"() {
            if (this.form.distribution_mode.indexOf("2") > -1) {
                if (this.data[4].items.length == 1) {
                    this.data[4].items.push({
                        label: "是否包邮",
                        key: "is_parcel",
                        type: "radio",
                        disabled: true,
                        options: [
                            { label: "包邮", value: "1" },
                            { label: "不包邮", value: "2" }
                        ],
                        default: "1"
                    });
                }
            } else {
                this.data[4].items.splice(1, 1);
                delete this.form.is_parcel;
            }
        },
        pluInfo() {
            this.form = Object.assign({}, this.form, {
                product_name: this.pluInfo.pluName,
                product_code: this.pluInfo.pluCode
            });
        }
    },
    methods: {
        onListBlur(e, key, index) {
            e = e.target.value;
            if (e === "0") e = "0";
            if (e > 99999) {
                e = "99999";
                this.$message.error("最大可设置99999；");
            }
            this.specListData[index][key] = e.replace(/^(0+)(\d+)/, "$2");
            this.specListData = JSON.parse(JSON.stringify(this.specListData));
        },
        onListInput(e, key, index) {
            if (key === "stkNum") {
                e = e.replace(/^(0+)(\d+)/, "$2");
                if (isNaN(e) || e % 1 !== 0 || e == "-" || e < 0) {
                    e = "";
                    this.$message.error("商品库存必须为正整数，请按规范填写");
                }
            }
            if (key === "price" || key === "skuWeight") {
                e =
                    e.split(".")[0] +
                    (e.split(".")[1] ? "." + e.split(".")[1].slice(0, 2) : "");
                if (e < 0) {
                    e = "";
                    this.$message.error("商品价格必须为正数，请按规范填写");
                }
            }
            this.specListData[index][key] = e;
            this.specListData = JSON.parse(JSON.stringify(this.specListData));
            // return item;
        },
        multiValueInput() {
            if (this.multiSettingSelect == "stkNum") {
                this.multiSettingValue = this.multiSettingValue.replace(
                    /^(0+)(\d+)/,
                    "$2"
                );
                if (
                    isNaN(this.multiSettingValue) ||
                    this.multiSettingValue % 1 !== 0 ||
                    this.multiSettingValue == "-" ||
                    this.multiSettingValue < 0
                ) {
                    this.multiSettingValue = "";
                    this.$message.error("商品库存必须为正整数，请按规范填写");
                    return;
                }
            }
            if (
                this.multiSettingSelect == "price" ||
                this.multiSettingSelect == "skuWeight"
            ) {
                this.multiSettingValue =
                    this.multiSettingValue.split(".")[0] +
                    (this.multiSettingValue.split(".")[1]
                        ? "." + this.multiSettingValue.split(".")[1].slice(0, 2)
                        : "");
                if (this.multiSettingValue < 0) {
                    this.multiSettingValue = "";
                    this.$message.error("商品价格必须为正数，请按规范填写");
                    return;
                }
            }
        },
        multiSetValue() {
            if (!this.multiSettingValue || this.multiSettingValue.trim() == "")
                return;
            if (isNaN(this.multiSettingValue.trim())) {
                this.$message.error("请输入数值");
                return;
            }
            if (this.multiSettingValue > 99999) {
                this.$message.error("批量设置数值最大为99999!");
                this.multiSettingValue = 99999;
            }
            this.load = true;
            this.specListData.forEach(item => {
                item[this.multiSettingSelect] = this.multiSettingValue;
                this.$set(
                    item,
                    this.multiSettingSelect,
                    this.multiSettingValue
                );
            });
            setTimeout(() => {
                this.load = false;
                this.multiSettingValue = "";
            }, 100);
            // this.specListData = temp;
        },
        evaluateSpecList() {
            let arr = [],
                result = [];
            this.specList.map(item => {
                let temp = [],
                    name = item.specName;
                name &&
                    item.specs.map(it => {
                        it.name && temp.push({ [name]: it.name });
                    });
                arr.push(temp);
            });
            if (arr[0] && arr[0].length > 0) {
                for (let i = 0, len = arr[0].length; i < len; i++) {
                    let spec_1 = arr[0][i];
                    if (arr[1] && arr[1].length > 0) {
                        for (let j = 0, le = arr[1].length; j < le; j++) {
                            let spec_2 = arr[1][j];
                            if (arr[2] && arr[2].length > 0) {
                                for (let k = 0, l = arr[2].length; k < l; k++) {
                                    let spec_3 = arr[2][k];
                                    result.push(
                                        Object.assign(
                                            {},
                                            spec_1,
                                            spec_2,
                                            spec_3
                                        )
                                    );
                                }
                            } else {
                                result.push(Object.assign({}, spec_1, spec_2));
                            }
                        }
                    } else {
                        result.push(spec_1);
                    }
                }
            }
            this.specListData = result;
        },
        onSpecNameBlur(it) {
            if (!isNaN(it.specName)) {
                this.$delete(it, "specName");
                this.$message.error("规格名不可以是数字");
            }
        },
        onSpecValueBlur(item, it, idx) {
            if (item.specs.length < 2) return;
            item.specs.map((spe, i) => {
                if (i !== idx && spe.name === it.name) {
                    this.$delete(it, "name");
                    this.$message.error("规格值重复");
                }
            });
        },
        onSpecChange(e) {
            this.evaluateSpecList();
        },
        selectPlu(plu) {
            this.pluInfo = plu;
            this.wackError = false;
        },
        // remoteIndustryCategory(query) {
        //     /**
        //      * 获取线下商品
        //      */
        //     return this.$get("/api/remoteIndustryCategory", { query });
        // },
        remoteProductCategory() {
            /**
             * 获取商品分类
             */
            return this.$post("/shop/api/goods/getAllOnCate", {});
        },
        remoteProductTag() {
            return this.$get("/api/remoteProductTag");
        },
        remoteDistributionMode() {
            return this.$get("/api/remoteDistributionMode");
        },
        remotePickupStore() {
            return this.$get("/api/remotePickupStore");
        },
        nextStep() {
            this.$refs.form1.validate(valid => {
                let flag = true,
                    validFlag = true;
                this.check = true;
                if (!this.pluInfo.pluName) {
                    this.wackError = true;
                } else {
                    this.wackError = false;
                }
                if (!this.specListData.length) {
                    this.$message.error("请完善商品规格！");
                    return;
                }
                if (!this.specList.length) {
                    this.$message.error("未找到商品规格名！");
                    return;
                } else {
                    let sum = 1;
                    this.specList.map(it => {
                        sum *= it.specs.length;
                    });
                    if (this.specListData.length !== sum) {
                        this.$message.error(
                            "规格名与规格列表数量不一致，请点击生成规格列表按钮！"
                        );
                        return;
                    }
                }
                if (this.specListData.length) {
                    this.specListData.map(item => {
                        if (item.stkNum === "") {
                            flag = false;
                        }
                        if (!item.price || parseFloat(item.price) === 0) {
                            flag = false;
                        }
                    });
                }
                validFlag = valid && flag;
                if (!validFlag) {
                    this.load = true;
                    this.$message.error("信息填写有误，请按规范填写");
                    setTimeout(() => {
                        this.load = false;
                    }, 100);
                    setTimeout(() => {
                        let _errorInput = document.querySelectorAll(
                            ".test-error.error"
                        );
                        if (_errorInput.length > 0) {
                            _errorInput[0].scrollIntoView();
                        }
                    }, 500);
                    return;
                }
                this.check = false;
                this.step = 2;
            });
        },
        prevStep() {
            this.step = 1;
        },
        cancel() {
            this.step = 1;
            this.form = {};
            this.$emit("update:visible", false);
            this.$emit("update:specEditFlag", false);
            this.$emit("update:page", {});
            this.$destroy(true);
        },
        blurDetail() {
            this.showError = !this.form.detail || this.form.detail.trim() == "";
        },
        submit() {
            /**
             * 提交信息
             */
            if (!this.form.detailInfo) {
                this.$message.error("请上传商品详情!");
                return;
            }
            let pluSku = {},
                skuList = [];
            this.specList.map(item => {
                pluSku[item.specName] = item.specs.map(it => it.name);
            });
            // this.specListData.map(item => {
            for (let j = 0, ln = this.specListData.length; j < ln; j++) {
                let item = this.specListData[j];
                let temp = {},
                    skuTmp = {},
                    specKeys = Object.keys(item);
                for (let i = 0, len = specKeys.length; i < len; i++) {
                    let ky = specKeys[i];
                    if (
                        ky == "stkNum" ||
                        ky == "price" ||
                        ky == "skuWeight" ||
                        ky == "skuCode"
                    ) {
                        temp[ky] = item[ky];
                    } else {
                        skuTmp[ky] = item[ky];
                    }
                }
                temp.skuDetail = JSON.stringify(skuTmp);
                skuList.push(temp);
            }

            //处理图片前缀
            // let iconImg = this.form.iconImg;
            // iconImg = iconImg.split("?")[0].replace(iconImg.split("?")[1], "");
            // let detailInfo = this.form.detailInfo;
            // detailInfo = detailInfo
            //     .split("?")[0]
            //     .replace(detailInfo.split("?")[1], "");
            // let pluImgList = [];
            // this.form.pluImgList.map(item => {
            //     pluImgList.push(
            //         item.split("?")[0].replace(item.split("?")[1], "")
            //     );
            // });
            // });
            let data = {
                pluId: this.pluInfo.pluId,
                fCateId: this.form.fCateId,
                sCateId: this.form.sCateId,
                pluSku: JSON.stringify(pluSku),
                iconImg: this.form.iconImg,
                pluImgList: this.form.pluImgList,
                selfDelivery:
                    this.form.distribution_mode.indexOf("1") > -1 ? 1 : 0,
                expDelivery:
                    this.form.distribution_mode.indexOf("2") > -1 ? 1 : 0,
                freeDelivery: this.form.is_parcel == "1" ? 1 : 0,
                pluName: this.form.product_name,
                pluCode: this.form.product_code,
                skuList: skuList,
                detailInfo: this.form.detailInfo,
                sno: this.form.sno
            };
            if (this.page.onPluId) {
                this.$post("/shop/api/goods/updatePlu", {
                    ...data,
                    onPluId: this.page.onPluId
                }).then(data => {
                    if (data) {
                        this.$message("更新成功");
                        this.cancel();
                    }
                });
            } else {
                this.$post("/shop/api/goods/addPlu", data).then(data => {
                    if (data) {
                        this.$message("新增成功");
                        this.cancel();
                    }
                });
            }
        },
        addSpec() {
            if (this.specList.length === 3) {
                this.$message.error("最多添加三个规格名");
                return;
            }
            this.specList.splice(this.specList.length, 0, { specs: [{}] });
            // if (this.specListData.length === 0) {
            //     this.specListData.splice(this.specListData.length, 0, {
            //         specs: [{}]
            //     });
            // }
        },
        addSpecValue(idx) {
            this.specList[idx].specs.push({});
        },
        closeSpec(idx) {
            this.specList.splice(idx, 1);
            // if (this.specList.length === 0) {
            //     this.specListData.length = 0;
            // }
        },
        closeSpecValue(index, idx) {
            this.specList[index].specs.splice(idx, 1);
        },
        wackPruDialog() {
            this.pruVisible = true;
        },
        closePruDialog() {
            this.pruVisible = false;
        }
    },
    components: { ...component, pruSelect }
};
</script>

<style lang="scss">
.error-wack {
    color: #f56c6c;
    position: absolute;
    top: 100%;
    left: 78px;
    font-size: 12px;
}
.wack-input-error .el-input__inner {
    border-color: #f56c6c !important;
}
.inner-page.publish-product-page {
    z-index: 10;
    background-color: #fff;
    overflow-y: auto;
    padding: 0 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &::-webkit-scrollbar {
        width: 0;
    }
    .icon-close-page {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
    }
    .steps {
        display: flex;
        justify-content: center;
        padding: 50px;
        margin-bottom: 20px;
        .step {
            width: 25%;
            position: relative;
            .line {
                height: 6px;
                width: 100%;
                background: #ccc;
            }
            .step-intro {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, calc(-50% + 15px));
                .circle {
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    background: #ccc;
                    margin: auto;
                }
                .text {
                    margin-top: 10px;
                }
            }
            &.active {
                .line,
                .step-intro .circle {
                    color: #fff;
                    background: rgb(45, 140, 240);
                }
            }
        }
    }
    .info-item {
        border: 1px solid #ddd;
        margin-bottom: 20px;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        .item-title {
            background-color: #f5f5f5;
            color: #333;
            padding: 10px 15px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid #ddd;
        }
        .common-item {
            padding: 20px 15px;
            display: flex;
            align-items: center;
            &.spec-common-item {
                display: block;
                .spec-item {
                    background: #eee;
                    margin-top: 10px;
                    position: relative;
                    .spec-extra {
                        padding: 10px;
                        display: flex;
                        align-items: baseline;
                        .label {
                            width: 50px;
                            flex: none;
                        }
                        .el-input {
                            width: 200px;
                        }
                        .add-spec-btn {
                            color: #2d8cf0;
                            cursor: pointer;
                            font-size: 12px;
                            font-weight: 600;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .el-form-item {
                margin-bottom: 0;
                display: flex;
                align-items: center;
            }
            .label {
                font-weight: 600;
                font-size: 12px;
                width: 100px;
            }
            .component-container {
                width: 400px;
            }
            .item-tips {
                margin-left: 20px;
                font-size: 12px;
                color: #999;
            }
        }
        .detail {
            width: 100%;
            .el-textarea {
                width: 100%;
                .el-textarea__inner {
                    width: 100%;
                }
            }
        }
    }
}
.wack_dialog-con {
    display: flex;
    position: relative;
    .label-1 {
        // text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        // padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 104px;
        &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 4px;
        }
    }
    .el-input.is-disabled .el-input__inner {
        cursor: pointer;
        background-color: #fff;
    }
}
.close-spec {
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    top: 5px;
    left: 250px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    .iconfont {
        color: #fff;
        font-weight: 600;
    }
}
.specs-container {
    display: flex;
    flex-wrap: wrap;
    .eip-con {
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        .close-spec {
            top: -4px;
            left: auto;
            right: -4px;
        }
    }
}
.spec-img-uploader {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 10px solid #333;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
}
.multi-setting-panel {
    display: flex;
    margin-top: 10px;
    .label-ml {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 12px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: auto;
        font-weight: 600;
    }
    .setting-select {
        width: 100px;
        margin-right: 20px;
    }
    .setting-value {
        width: 100px;
        margin-right: 20px;
    }
}
.spec-table {
    height: 100%;
}
.table-container-panel {
    position: relative;
    height: 300px;
    &.high {
        height: calc(100% - 100px);
    }
    &.loading::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.1);
        filter: blur(5px);
    }
}
.test-error.error .el-input__inner {
    border-color: red;
}
</style>