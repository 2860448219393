<template>
    <el-dialog
        :visible.sync="visible"
        :append-to-body="true"
        :before-close="close"
        :destroy-on-close="true"
        top="5vh"
        title="选择商品"
        width="900px"
        custom-class="pru-select-dialog"
        @opened="onOpenDialogCallBack"
    >
        <div class="pru-select-container">
            <Search
                :items="sitems"
                :data.sync="sdata"
                @search="search"
                :small="true"
            />
            <el-table
                :data="tdata"
                max-height="300px"
                highlight-current-row
                @current-change="handleCurrentChange"
            >
                <el-table-column
                    v-for="cln in tcolumn"
                    :key="cln.key"
                    :prop="cln.key"
                    :label="cln.label"
                ></el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50]"
                    :page-size.sync="pageSize"
                    layout="sizes, prev, pager, next, jumper"
                    :total="total"
                    :hide-on-single-page="true"
                ></el-pagination>
            </div>
        </div>
        <div slot="footer">
            <el-button type="primary" @click="sure">确定</el-button>
            <el-button type="info" plain @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            sitems: [
                { label: "商品名称", key: "pluName" },
                { label: "商品编码", key: "pluCode" }
            ],
            sdata: {},
            tdata: [],
            tcolumn: [
                { label: "商品编码", key: "pluCode" },
                { label: "商品名称", key: "pluName" },
                { label: "业态编码", key: "cateCode" },
                { label: "业态名称", key: "cateName" },
                { label: "品牌编码", key: "bndCode" },
                { label: "品牌名称", key: "bndName" }
            ],
            current: null,
            currentPage: 1,
            pageSize: 10,
            total: 0
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        search() {
            this.getPruList();
        },
        getPruList() {
            // debugger;
            this.$post("/shop/api/goods/getPluList", {
                pageIndex: this.currentPage,
                pageSize: this.pageSize,
                ...this.sdata
            }).then(data => {
                this.tdata = data.datas;
                this.total = data.recordCount;
            });
        },
        sure() {
            if (!this.current) {
                this.$message({
                    showClose: true,
                    message: "请选择商品",
                    type: "error"
                });
                return;
            }
            this.$emit("selectPlu", this.current);
            this.close();
        },
        close() {
            this.current = null;
            this.currentPage = 1;
            this.pageSize = 10;
            this.tdata = [];
            this.sdata = {};
            this.$emit("update:visible", false);
        },
        handleCurrentChange(curr) {
            this.current = JSON.parse(JSON.stringify(curr));
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.getPruList();
        },
        handleCurrChange(curr) {
            this.currentPage = curr;
            this.getPruList();
        },
        onOpenDialogCallBack() {
            this.getPruList();
        }
    }
};
</script>
<style lang="scss">
.pru-select-dialog {
    .el-dialog__body {
        padding-top: 0;
        padding-bottom: 10px;
    }
    .btn-content {
        box-shadow: none;
    }
    .el-table__body tr.current-row > td {
        background-color: #66b1ff;
    }
    .page-search-panel .search-item {
        padding-bottom: 5px;
        margin-right: 30px;
    }
    .page-search-panel.showExpand {
        padding-right: 0;
    }
    .page-search-panel .expand-btn {
        display: none;
    }
    .pagination {
        position: absolute;
        bottom: 25px;
        left: 10px;
    }
}
</style>