var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.specEditFlag)?_c('div',{staticClass:"spec-edit-panel"},[_c('i',{staticClass:"iconfont icon-chuyidong1 icon-close-page",on:{"click":_vm.cancel}}),_c('div',{staticClass:"sno-edit-panel"},[_c('div',{staticClass:"label"},[_vm._v("商品序号")]),_c('el-input',{attrs:{"type":"number","max":99999},model:{value:(_vm.sno),callback:function ($$v) {_vm.sno=$$v},expression:"sno"}}),_c('div',{staticClass:"tip"},[_vm._v("（用户端将按序号从小到大进行展示）")]),(_vm.showSnoError)?_c('div',{staticClass:"sno-error-tip"},[_vm._v("*请输入正整数")]):_vm._e()],1),_c('div',{staticClass:"spec-table"},[_c('div',{staticClass:"multi-setting-panel"},[_c('div',{staticClass:"label-ml"},[_vm._v("批量设置")]),_c('el-select',{staticClass:"setting-select",model:{value:(_vm.multiSettingSelect),callback:function ($$v) {_vm.multiSettingSelect=$$v},expression:"multiSettingSelect"}},_vm._l((_vm.multiSettingOption),function(opt){return _c('el-option',{key:opt.value,attrs:{"value":opt.value,"label":opt.label}})}),1),_c('el-input',{staticClass:"setting-value",attrs:{"type":"number","maxlength":"8"},on:{"input":_vm.multiValueInput},model:{value:(_vm.multiSettingValue),callback:function ($$v) {_vm.multiSettingValue=$$v},expression:"multiSettingValue"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.multiSetValue}},[_vm._v("确定")])],1),(_vm.specList.length > 0)?_c('div',{class:['table-container-panel', { loading: _vm.load }]},[(!_vm.load)?_c('el-table',{attrs:{"data":_vm.specListData,"height":"100%"},on:{"update:data":function($event){_vm.specListData=$event}}},[_vm._l((_vm.specList),function(item,index){return [_c('el-table-column',{key:item + '' + index,attrs:{"label":item,"prop":item}})]}),_c('el-table-column',{attrs:{"label":"库存(件)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{class:[
                                'test-error',
                                {
                                    error:
                                        scope.row.stkNum === '' &&
                                        _vm.check
                                }
                            ],attrs:{"value":scope.row.stkNum,"maxlength":"8","type":"number"},on:{"input":function($event){return _vm.onListInput($event, 'stkNum', scope.$index)},"blur":function($event){return _vm.onListBlur($event, 'stkNum', scope.$index)}}})]}}],null,false,3615588386)},[_c('template',{slot:"header"},[_c('p',[_c('b',{staticStyle:{"color":"red"}},[_vm._v("* ")]),_vm._v(" 库存(件) ")])])],2),_c('el-table-column',{attrs:{"label":"商品价格(元)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{class:[
                                'test-error',
                                {
                                    error:
                                        (!scope.row.price ||
                                            scope.row.price == 0) &&
                                        _vm.check
                                }
                            ],attrs:{"value":scope.row.price,"maxlength":"8","type":"number"},on:{"input":function($event){return _vm.onListInput($event, 'price', scope.$index)},"blur":function($event){return _vm.onListBlur($event, 'price', scope.$index)}}})]}}],null,false,3052235656)},[_c('template',{slot:"header"},[_c('p',[_c('b',{staticStyle:{"color":"red"}},[_vm._v("* ")]),_vm._v(" 商品价格(元) ")])])],2),_c('el-table-column',{attrs:{"label":"重量(kg)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"value":scope.row.skuWeight,"maxlength":"8","type":"number"},on:{"input":function($event){return _vm.onListInput(
                                    $event,
                                    'skuWeight',
                                    scope.$index
                                )},"blur":function($event){return _vm.onListBlur(
                                    $event,
                                    'skuWeight',
                                    scope.$index
                                )}}})]}}],null,false,1509568843)}),_c('el-table-column',{attrs:{"label":"商品条码"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"maxlength":"10"},model:{value:(scope.row.skuCode),callback:function ($$v) {_vm.$set(scope.row, "skuCode", $$v)},expression:"scope.row.skuCode"}})]}}],null,false,2197472138)})],2):_vm._e()],1):_vm._e(),_c('div',{staticClass:"btn-content"},[_c('p',{staticClass:"search-btn reset",on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('p',{staticClass:"search-btn search",on:{"click":_vm.submit}},[_vm._v("保存")])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }