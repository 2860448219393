export default {
    data() {
        var validateSno = (rule, value, callback) => {
            if (value && !(/(^[1-9]\d*$)/.test(value))) {
                callback(new Error("请输入正整数"));
            } else {
                callback();
            }
        };
        return {
            data: [
                {
                    title: "分类信息",
                    items: [
                        {
                            label: "线下商品",
                            type: "wack_dialog"
                        }, {
                            label: "商品分类",
                            key: "product_category",
                            type: "cascader",
                            level: 2,
                            props: {
                                expandTrigger: "hover",
                                value: "cateId",
                                label: "cateName",
                                children: "childCate"
                            },
                            remote: this.remoteProductCategory
                        }
                    ]
                }, {
                    title: "基本信息",
                    items: [
                        {
                            label: "商品名称",
                            key: "product_name",
                            type: "input"
                        }, {
                            label: "商品编码",
                            key: "product_code",
                            disabled: true
                            // }, {
                            //     label: "商品条码",
                            //     key: "product_bar_code"
                        }, {
                            label: "商品序号",
                            key: "sno",
                            inputType: "number",
                            maxlength: "5",
                            tip: "（用户端将按序号从小到大进行展示）"
                        }
                    ]
                }, {
                    title: "商品图片",
                    type: "img",
                    items: [
                        {
                            label: "缩略图片",
                            key: "iconImg",
                            type: "upload",
                            tip: "（尺寸：300px*300px）",
                            limit: 1,
                            files: true
                        }, {
                            label: "轮播图片",
                            key: "pluImgList",
                            type: "upload",
                            multiple: true,
                            tip: "（尺寸：750px*750px）",
                            limit: 5,
                            files: true
                        }
                    ]
                }, {
                    title: "规格价格",
                    type: "spec_price"
                }, {
                    title: "配送信息",
                    items: [
                        {
                            label: "配送方式",
                            key: "distribution_mode",
                            type: "checkbox",
                            options: [
                                { label: "快递发货", value: "2" },
                                { label: "门店自提", value: "1" }
                            ],
                            default: ["1", "2"]
                        }
                    ]
                }
            ],
            rule1: {
                product_category: { required: true, message: "请选择商品分类", trigger: "change" },
                product_name: { required: true, message: "请输入商品名称", trigger: ["blur", "change"] },
                product_code: { required: true, message: "请输入商品编码", trigger: ["blur", "change"] },
                iconImg: { required: true, message: "请上传缩略图片", trigger: "change" },
                pluImgList: { required: true, message: "请上传轮播图片", trigger: "change" },
                distribution_mode: { required: true, message: "请选择配送方式", trigger: "change" },
                sno: { validator: validateSno, trigger: ["blur", "change"] }
            }
        }
    }
}