export default {
    data() {
        return {
            searchItems: [
                // { label: "行业类目", key: "industry_category", type: "select", remote: this.remoteIndustryCategory },
                {
                    label: "商品分类", key: "product_category", type: "cascader", remote: this.remoteProductCategory, props: {
                        expandTrigger: "hover",
                        value: "cateId",
                        label: "cateName",
                        children: "childCate"
                    },
                },
                { label: "商品名称", key: "pluName" },
                // { label: "实际销量", key: "actual_sales", type: "range" },
                // { label: "商品库存", key: "product_stock", type: "range" },
                { label: "商品编码", key: "pluCode" }
            ],
            options: [
                {
                    label: "发布商品",
                    type: "add"
                }
            ],
            handles: {
                add: {
                    cb: this.addCallBack
                }
            },
            tableColumns: [
                { label: "商品图片", prop: "iconImg", type: "img", width: "100" },
                { label: "商品信息", prop: "pluName", subProp: "pluCode", width: "150" },
                { label: "库存", prop: "stkNum", width: "100" },
                { label: "销量", prop: "saleNum" },
                { label: "创建时间", prop: "createDate" },
                { label: "商品状态", prop: "sta" }
            ],
            // tableOperate: [
            //     {
            //         icon: "icon-bar-edit",
            //         title: "修改",
            //         handle: this.edit
            //     }
            // ],
            tableOperate: {
                key: "sta",
                data: {
                    1: [
                        {
                            icon: "icon-bar-edit",
                            title: "修改",
                            handle: this.edit
                        }, {
                            icon: "icon-bar-delete",
                            title: "删除",
                            handle: this.updateStat,
                            back: "#e95464"
                        }
                    ],
                    5: [
                        {
                            icon: "icon-bar-edit",
                            title: "编辑规格",
                            handle: this.editSpec
                        },
                        {
                            icon: "icon-tingyong6",
                            title: "下架",
                            handle: this.updateStat,
                            back: "#9e9478"
                        }, {
                            icon: "icon-fuzhi1",
                            title: "复制链接",
                            handle: this.copyLink,
                            back: "#28FF28"
                        }
                    ],
                    9: [
                        {
                            icon: "icon-bar-edit",
                            title: "编辑规格",
                            handle: this.editSpec
                        },
                        {
                            icon: "icon-shangchuan2",
                            title: "上架",
                            handle: this.updateStat,
                            back: "#3eb370"
                        }
                    ]
                }
            },
            tabMap: {
                sta: {
                    1: "待审核",
                    5: "已上架",
                    9: "已下架"
                }
            },
            tableTabs: [
                { label: "全部", value: -1 },
                { label: "待审核", value: 1 },
                { label: "已上架", value: 5 },
                { label: "已下架", value: 9 }
            ]
        }
    }
}