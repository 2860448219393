<template>
    <div class="spec-edit-panel" v-if="specEditFlag">
        <i class="iconfont icon-chuyidong1 icon-close-page" @click="cancel"></i>
        <div class="sno-edit-panel">
            <div class="label">商品序号</div>
            <el-input v-model="sno" type="number" :max="99999"></el-input>
            <div class="tip">（用户端将按序号从小到大进行展示）</div>
            <div v-if="showSnoError" class="sno-error-tip">*请输入正整数</div>
        </div>
        <div class="spec-table">
            <div class="multi-setting-panel">
                <div class="label-ml">批量设置</div>
                <el-select class="setting-select" v-model="multiSettingSelect">
                    <el-option
                        v-for="opt in multiSettingOption"
                        :key="opt.value"
                        :value="opt.value"
                        :label="opt.label"
                    ></el-option>
                </el-select>
                <el-input
                    class="setting-value"
                    type="number"
                    maxlength="8"
                    @input="multiValueInput"
                    v-model="multiSettingValue"
                />
                <el-button type="primary" @click="multiSetValue"
                    >确定</el-button
                >
            </div>
            <div
                v-if="specList.length > 0"
                :class="['table-container-panel', { loading: load }]"
            >
                <el-table :data.sync="specListData" v-if="!load" height="100%">
                    <template v-for="(item, index) in specList">
                        <el-table-column
                            :key="item + '' + index"
                            :label="item"
                            :prop="item"
                        ></el-table-column>
                    </template>
                    <el-table-column label="库存(件)">
                        <template slot="header">
                            <p>
                                <b style="color: red">* </b>
                                库存(件)
                            </p>
                        </template>
                        <template slot-scope="scope">
                            <el-input
                                :value="scope.row.stkNum"
                                maxlength="8"
                                @input="
                                    onListInput($event, 'stkNum', scope.$index)
                                "
                                @blur="
                                    onListBlur($event, 'stkNum', scope.$index)
                                "
                                type="number"
                                :class="[
                                    'test-error',
                                    {
                                        error:
                                            scope.row.stkNum === '' &&
                                            check
                                    }
                                ]"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品价格(元)">
                        <template slot="header">
                            <p>
                                <b style="color: red">* </b>
                                商品价格(元)
                            </p>
                        </template>
                        <template slot-scope="scope">
                            <el-input
                                :value="scope.row.price"
                                maxlength="8"
                                @input="
                                    onListInput($event, 'price', scope.$index)
                                "
                                @blur="
                                    onListBlur($event, 'price', scope.$index)
                                "
                                type="number"
                                :class="[
                                    'test-error',
                                    {
                                        error:
                                            (!scope.row.price ||
                                                scope.row.price == 0) &&
                                            check
                                    }
                                ]"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="重量(kg)">
                        <template slot-scope="scope">
                            <el-input
                                :value="scope.row.skuWeight"
                                maxlength="8"
                                type="number"
                                @input="
                                    onListInput(
                                        $event,
                                        'skuWeight',
                                        scope.$index
                                    )
                                "
                                @blur="
                                    onListBlur(
                                        $event,
                                        'skuWeight',
                                        scope.$index
                                    )
                                "
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品条码">
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.skuCode"
                                maxlength="10"
                            ></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="btn-content">
                <p class="search-btn reset" @click="cancel">取消</p>
                <p class="search-btn search" @click="submit">保存</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            check: false,
            load: false,
            specList: [],
            specListData: [],
            multiSettingSelect: "stkNum",
            multiSettingValue: "",
            multiSettingOption: [
                { label: "库存", value: "stkNum" },
                { label: "商品价格", value: "price" },
                { label: "重量", value: "skuWeight" }
            ],
            sno: "",
            showSnoError: false
        };
    },
    props: {
        specEditFlag: {
            type: Boolean,
            default: false
        },
        page: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    watch: {
        sno() {
            if (this.sno.length > 5) {
                this.sno = this.sno.toString().substr(0, 5);
            }
            if (this.sno && !(/(^[1-9]\d*$)/.test(this.sno))) {
                this.showSnoError = true;
            } else {
                this.showSnoError = false;
            }
        }
    },
    computed: {
        onPluId() {
            return this.page.onPluId;
        }
    },
    mounted() {
        this.$post("/shop/api/goods/getPluSkus", {
            onPluId: this.page.onPluId
        }).then(data => {
            if (!data || data.length < 1) return;
            data.forEach(item => {
                let skuDetail = JSON.parse(item.skuDetail),
                    skuKeys = Object.keys(skuDetail);
                if (this.specList.length < 1) {
                    this.specList = skuKeys;
                }
                for (let i = 0, len = skuKeys.length; i < len; i++) {
                    item[skuKeys[i]] = skuDetail[skuKeys[i]];
                }
            });
            this.specListData = data;
            this.sno = data[0].sno;
        });
    },
    methods: {
        onListBlur(e, key, index) {
            e = e.target.value;
            if (e == 0) e = "0";
            if (e > 99999) {
                e = "99999";
                this.$message.error("最大可设置99999；");
            }
            this.specListData[index][key] = e.replace(/^(0+)(\d+)/, "$2");
            this.specListData = JSON.parse(JSON.stringify(this.specListData));
        },
        onListInput(e, key, index) {
            if (key === "stkNum") {
                e = e.replace(/^(0+)(\d+)/, "$2");
                if (isNaN(e) || e % 1 !== 0 || e == "-" || e < 0) {
                    e = "";
                    this.$message.error("商品库存必须为正整数，请按规范填写");
                }
            }
            if (key === "price" || key === "skuWeight") {
                console.log(e);
                e =
                    e.split(".")[0] +
                    (e.split(".")[1] ? "." + e.split(".")[1].slice(0, 2) : "");
                if (e < 0) {
                    e = "";
                    this.$message.error("商品价格必须为正数，请按规范填写");
                }
            }
            this.specListData[index][key] = e;
            this.specListData = JSON.parse(JSON.stringify(this.specListData));
            // return item;
        },
        multiValueInput() {
            if (this.multiSettingSelect == "stkNum") {
                this.multiSettingValue = this.multiSettingValue.replace(
                    /^(0+)(\d+)/,
                    "$2"
                );
                if (
                    isNaN(this.multiSettingValue) ||
                    this.multiSettingValue % 1 !== 0 ||
                    this.multiSettingValue == "-" ||
                    this.multiSettingValue < 0
                ) {
                    this.multiSettingValue = "";
                    this.$message.error("商品库存必须为正整数，请按规范填写");
                    return;
                }
            }
            if (
                this.multiSettingSelect == "price" ||
                this.multiSettingSelect == "skuWeight"
            ) {
                this.multiSettingValue =
                    this.multiSettingValue.split(".")[0] +
                    (this.multiSettingValue.split(".")[1]
                        ? "." + this.multiSettingValue.split(".")[1].slice(0, 2)
                        : "");
                if (this.multiSettingValue < 0) {
                    this.multiSettingValue = "";
                    this.$message.error("商品价格必须为正数，请按规范填写");
                    return;
                }
            }
        },
        multiSetValue() {
            if (!this.multiSettingValue || this.multiSettingValue.trim() == "")
                return;
            if (isNaN(this.multiSettingValue.trim())) {
                this.$message.error("请输入数值");
                return;
            }
            this.load = true;
            this.specListData.forEach(item => {
                item[this.multiSettingSelect] = this.multiSettingValue;
                this.$set(
                    item,
                    this.multiSettingSelect,
                    this.multiSettingValue
                );
            });
            setTimeout(() => {
                this.load = false;
                this.multiSettingValue = "";
            }, 100);
            // this.specListData = temp;
        },
        cancel() {
            this.specList = [];
            this.specListData = [];
            this.$emit("update:specEditFlag", false);
            this.$emit("update:page", {});
            this.$destroy(true);
        },
        submit() {
            this.check = true;
            let flag = true;
            if (this.specListData.length) {
                this.specListData.map(item => {
                    console.log(!item.stkNum);
                    if (item.stkNum === "") {
                        flag = false;
                    }
                    if (!item.price || parseFloat(item.price) === 0) {
                        flag = false;
                    }
                });
            }
            if (!flag || this.showSnoError) {
                this.$message.error("信息填写有误，请按规范填写");
                return;
            }
            this.check = false;
            this.$post("/shop/api/goods/updatePluSkus", {
                onPluId: this.onPluId,
                sno: this.sno,
                skuList: this.specListData
            }).then(data => {
                this.$message("更新成功");
                this.$emit("refresh");
                this.cancel();
            });
        }
    }
};
</script>

<style lang="scss">
.spec-edit-panel {
    z-index: 10;
    background-color: #fff;
    overflow-y: hidden;
    padding: 0 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &::-webkit-scrollbar {
        width: 0;
    }
    .icon-close-page {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        z-index: 99;
    }
    .sno-edit-panel {
        padding: 20px 0;
        display: flex;
        align-items: center;
        position: relative;
        .sno-error-tip {
            position: absolute;
            bottom: 6px;
            left: 50px;
            color: red;
            font-size: 12px;
            transform: scale(.8);
        }
        .label {
            margin-right: 12px;
            font-weight: 600;
            color: #606266;
        }
        .el-input {
            width: 200px;
        }
    }
    .spec-table {
        height: calc(100% - 68px);
        .multi-setting-panel {
            display: flex;
            margin-top: 10px;
            .label-ml {
                text-align: right;
                vertical-align: middle;
                float: left;
                font-size: 12px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: auto;
                font-weight: 600;
            }
            .setting-select {
                width: 100px;
                margin-right: 20px;
            }
            .setting-value {
                width: 100px;
                margin-right: 20px;
            }
        }
        .table-container-panel {
            position: relative;
            height: calc(100% - 100px);
            &.loading::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.1);
                filter: blur(5px);
            }
        }
    }
    .test-error.error .el-input__inner {
        border-color: red;
    }
}
</style>